.header {
  .logo {
    max-width: 80%;
    width: 80%;
  }
  
  .user {
    &-btn {
      @media (max-width: 640px) {
        display: none;
      }

      .icon-gear {
        margin-top: 2px;
        margin-left: 5px;
      }
    }
    
    &-avatar {
      margin-left: 15px;
    }
  }

  .v-toolbar__title {
    width: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  
  &-btn {
    @extend %btn-header;
    margin-right: 10px;

    @media (max-width: 420px) {
      // display: none;
    }
  }

  &-autocomplete {
    margin-right: 15px !important;
    width: 460px;
    background-color: transparent;

    @media (max-width: 640px) {
      display: none;
    }

    .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-text-field__details {
      position: absolute;
    }
    
    &.v-text-field--outlined {
      > .v-input__control > .v-input__slot {
        min-height: 36px !important;
        background-color: transparent !important;
        padding-left: 40px !important;
        // margin-top: 5px !important;
      }

      .v-label {
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.75em;
      }

      .v-label--active {
        transform: translateY(-25px) scale(0.75);
      }

      fieldset {
        border-radius: 0;
        border-color: #FFF;
        border-width: 1px;
      }
    }

    &.v-text-field.v-text-field--enclosed .v-input__append-inner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
    }

    &.v-text-field--solo .v-input__control {
      min-height: 36px;
      min-width: 210px;
    }

    .v-input__prepend-outer {
      position: absolute;
      top: 50%;
      left: 10px;
      margin: 0 !important;
      transform: translateY(-50%)
    }
  }

  .v-toolbar__content {
    padding-top: 0;
    padding-bottom: 0;
  }

  .scroll-area {
    width: 100%;
    margin: 0 15px;
    display: flex;
    height: 100%;
  }

  .ps--active-x>.ps__rail-x,
  .ps--active-x>.ps__rail-x:hover, 
  .ps--active-y>.ps__rail-y,
  .ps--active-y>.ps__rail-y:hover {
    background: transparent;
  }

  .draw-aside-menu {
    // @media (min-width: 960px) {
    //   display: none;
    // }
  }
}


.v-application--is-ltr .header .v-toolbar__title {
  padding-left: 0 !important;

  @media (max-width: 960px) {
    // padding-left: 20px !important;
  }
}