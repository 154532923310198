@import './variables';

%border-radius {
	border-radius: 12px;
}

%transition {
	transition: all .2s ease-in-out;
}

%box-shadown {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.17);
}

%btn-header {
  background-color: #FFF !important;
  color: $colorPrimary !important;
  font-weight: bold;
}
