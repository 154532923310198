


































































































































































































































@import '../../../assets/styles/helpers/shareds';
@import '../../../assets/styles/components/header';
.header {
  .logo {
    cursor: pointer;
  }
  &-menu{
    &-itens {
      .v-btn__content {
        text-transform: capitalize;
      }
    }
  }
}
